import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { Title, Section, Box, Text, Span } from "../../components/Core";
import { device } from "../../utils";

import imgMobile from "../../assets/image/jpeg/teart.jpg";
import Newarrow from '../../assets/image/jpeg/enter.svg'


const Bookbutton = styled.button`
font-size: 16px;
padding: 12px 34px;
    background-color: #006b7b;
    display: block;
    color: #fff;
    border-radius: 32px;
    transition: .5s;
    border: none;
   

  @media screen and (max-width: 767px) {
    background-color: #006b7b;
    display: inline-block;
    padding: 12px 34px;
    font-size: 14px;
  }
`;


const Iwrap = styled.div`
  display: flex;
  margin-bottom: 1em;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin-bottom: 8px;
    justify-content: flex-start;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 8px;
    justify-content: center;
    text-align: center;
  }
`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;

const Secondtext = styled.h2`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`;


const ContentCard = ({ iconImage, title, className, children, ...rest }) => (
  <Box
    {...rest}
    className={`d-flex align-items-start justify-content-start ${className}`}
  >
    <Box mr={4}>
      <img src={iconImage} alt="" />
    </Box>
    <Box>
      <Title variant="card" fontSize="24px" mb={3}>
        {title}
      </Title>
      <Text variant="small">{children}</Text>
    </Box>
  </Box>
);

const ImgStyled = styled.img`

  box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow}`};
  border-radius: 0px;
  max-width: 100%;
  @media ${device.sm} {
    max-width: 50%;
  }
  @media ${device.md} {
    max-width: 33%;
  }
  @media ${device.lg} {
    max-width: 100%;
  }
  @media ${device.xl} {
    
  }
`;

const MasseterContentPage = () => (
  <>
    {/* <!-- Content section 2 --> */}
    <Section py={4}>
      <Container className="pt-3 pb-3">

          <Row className="pb-4">
              <Col>
                <Iwrap>


<Itext as="h2">Masseter Treatment <span>UK</span></Itext>
</Iwrap>
      <Secondtext>
      What is Masseter (The Side Set Jaw)?

      </Secondtext>
              </Col>
          </Row>
        <Row className="align-items-center">
          <Col lg="6" className="mb-4  mb-lg-0">
            <div
              className="pl-0"
              data-aos="fade-up"
              data-aos-duration="750"
              data-aos-delay="200"
              data-aos-once="true"
            >
              <img src={imgMobile} alt="What is non surgical rhinoplasty" className="rounded shadow" />
            </div>
          </Col>
          <Col lg="6" md="9">
            <Box className=" pt-lg-0">
          
              <Text>

              The shape of the jawline can impact your face more than you think. Treatment of the masseter muscle, which is pair of muscles that is found on either side of the face, situated at the corner of the jawlines can help to soften and define the features of the face.<br /> <br />

Certain individuals develop the masseter muscle from habits, resulting from the grinding and clenching of the teeth tightly. This prolonged habit leads to the muscle thickening over the years, leading to a wider jaw and a more masculine look.<br /> <br />

This relaxation in the muscle would result in a slimmer and more contoured face.<br /> <br />






              </Text>
            
              <a href="https://connect.pabau.com/bookings.php?compid=8607" activeClassName="active"><Bookbutton>Book Appointment
                </Bookbutton> </a>
           
            </Box>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default MasseterContentPage;
